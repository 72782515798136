import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Air() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Air Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Air Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Air Freight</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/air.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/><br/>
            {companyname} specializes in providing efficient and expedited air freight services to destinations worldwide. Our airport-to-airport service is designed to cater to customers with high-volume business requirements and time-critical delivery needs. We offer flexible air freight services tailored to your specific schedule and needs.<br/><br/>

Our expertise lies in selecting the most suitable carrier for your shipment, giving you a competitive advantage over other air freight forwarding companies. By considering factors such as seasonal timetables and size and weight restrictions, we ensure cost-effective and timely shipping of your freight globally, minimizing unnecessary delays and expenses.<br/><br/>

We handle air freight shipments of all sizes and weights, both domestically and internationally, guaranteeing on-time delivery. Our specialization in meeting the needs of the air transport industry allows us to provide a wide range of customized services, including import customs clearance, brokerage, insurance, consolidations, packing, and logistics, all at highly competitive rates.<br/><br/>

Recognizing the importance of timeliness and punctuality in air cargo transportation, our dedicated air cargo staff maintains close relationships with all airlines operating in India. This enables us to offer clients the best possible service, ensuring efficient handling of their air freight shipments.<br/><br/>

We take pride in offering excellent air freight forwarding services at reasonable costs to almost every country in the world. Our extensive network of global air freight partners enables us to handle a diverse range of shipments, from printed materials to electronic equipment and automobile components, with the utmost professionalism, efficiency, and reliability. While we provide a wide range of services, our most valuable asset is our personalized approach to customer service.<br/><br/>

Some of the key features of our air freight services include:<br/><br/>

- Worldwide consolidation services<br/>
- Cargo pick-up from the consignee's warehouse<br/>
- Expediting and vendor management<br/>
- Export clearance at the country of origin<br/>
- Import clearance at the port of discharge<br/>
- Export packing services<br/>
- Documentation specialists<br/>
- Track and trace system for shipment monitoring<br/>
- Safe handling of general and odd-dimensional cargo<br/>
- Door-to-door express products for convenient delivery.<br/>

            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
