import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function whoweare() {
  return (
    <>
      <Header />
      {/* Page Header Start */}
      <div className="container-fluid page-header mb-5 wow fadeIn" data-wow-delay="0.1s">
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">About Us</h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item"><Link className="text-white" to="/Home">Home</Link></li>
              <li className="breadcrumb-item text-primary active" aria-current="page">About Us</li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100 pt-5 pe-5"
                  src="img/about-1.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <img
                  className="position-absolute top-0 end-0 bg-white ps-2 pb-2"
                  src="img/about-2.jpg"
                  alt=""
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                  About Us
                </div>
                <h1 className="display-6 mb-5">Welcome to {companyname}</h1>

                <p className="mb-5">
                  With an extensive network of partners worldwide, we take pride
                  in providing our customers with seamless transportation
                  services, including air and sea freight forwarding.
                  Additionally, we offer reliable warehousing and storage
                  solutions, efficient handling of project cargo, break bulk
                  shipments, and chartering services.
                  <br />
                  <br />
                  Our company is dedicated to facilitating international trade
                  and supporting organizations in achieving consistent growth.
                  We handle a wide range of shipping services and engage in
                  custom clearing activities and initiatives to ensure smooth
                  and efficient operations for our clients.
                  <br />
                  <br />
                  Through our contracts with leading steamship lines and
                  airlines, we are able to deliver innovative and reliable
                  logistics solutions that adapt to evolving business
                  requirements. We offer competitive rates and voluminous
                  services tailored to meet the specific needs of our customers
                  in every trade lane.
                  <br />
                  <br />
                  Our commitment extends to providing comprehensive assistance
                  for import and export processes. We prioritize timeliness,
                  hassle-free deliveries, reliability, and top-notch quality,
                  consistently aiming to meet international standards and exceed
                  customer expectations.
                </p>
                
              </div>
            </div>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <i
                  class="fa fa-bullseye fa-6x"
                  style={{ color: "#FF6F0F" }}
                  aria-hidden="true"
                ></i>
                <h4 className="mb-3 mt-3">Our Mission</h4>
                <p className="mb-4">
                  Our mission is to simplify the lives of our customers by
                  offering value-added, innovative, reliable, and cost-effective
                  logistics services that enhance their business efficiency and
                  productivity. We strive to build long-term relationships based
                  on growth, trust, and delivering on our promises. We are
                  dedicated to ensuring the safe and timely delivery of cargo,
                  regardless of its size or location.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <i
                  class="fa fa-eye fa-6x"
                  style={{ color: "#FF6F0F" }}
                  aria-hidden="true"
                ></i>
                <h4 className="mb-3 mt-3">Our Vision</h4>
                <p className="mb-4">
                  We aim to establish a prominent global logistics brand that
                  offers fully integrated, cost-effective, and efficient supply
                  chain solutions. Our focus is on delivering exceptional levels
                  of service, reliability, and control by adopting a
                  customer-centric approach. By prioritizing the needs of our
                  clients, we strive to provide comprehensive and seamless
                  logistics services that optimize their operations and drive
                  their success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}

      <Footer />
    </>
  );
}
