import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Sea() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Sea Freight
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Sea Freight
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Sea Freight</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/sea.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/>
              <br />
              We offer a comprehensive range of ocean freight forwarding
              services, without any restrictions on size or weight. Our services
              encompass door-to-door pick-up, advanced tracking technology, and
              efficient management of shipping documents.
              <br />
              <br />
              As a highly efficient international ocean freight forwarder, we
              provide comprehensive services to numerous seaport locations. Our
              experienced associates are well-versed in navigating the
              complexities of international shipping. We tailor our programs to
              meet our clients' specific requirements, whether it's for full
              container load (FCL) or less than container load (LCL), while
              considering transportation cost objectives.
              <br />
              <br />
              With a significant volume of ocean freight forwarding services, we
              can offer our clients the most competitive rates in the market.
              Our team of professionals is dedicated to assisting clients with
              any queries regarding entry, delivery, tracking, and billing
              details of their shipments. Throughout the entire process, we
              maintain open communication with clients to ensure that their
              shipments are promptly delivered to the carrier and reach their
              desired destination within the agreed timeframe.
              <br />
              <br />
              Our comprehensive services include shipment tracking, purchase
              order management, and space protection during peak demand periods.
              By partnering with multiple carriers, {companyname} serves as a single-source logistics window,
              capable of providing solutions for any shipping requirement within
              your supply chain.
              <br />
              <br />
              Our customized services include: <br />
              <br />
              - Consolidation of cargo
              <br />
              - Full-container-load (FCL) consolidation
              <br />
              - Less-than-container-load (LCL) consolidation
              <br />
              - Multimodal transportation by sea, air, and road
              <br />
              - Worldwide consolidation service
              <br />
              - Handling of dry or liquid bulk shipments
              <br />
              - Refrigerated services for perishable products
              <br />
              - Freight insurance and various packing options
              <br />
              - Comprehensive documentation services
              <br />
              - Cargo tracking system
              <br />
              - Domestic and international freight forwarding
              <br />
              <br />
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
