import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Industries() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Our Services
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Our Services
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      {/* Causes Start */}
      <div className="container-xxl bg-light my-5 py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Freight Services
            </div>
            <h1 className="display-6 mb-5">
              Shipping Simplified, Success Amplified
            </h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/sea.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Sea">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>We offer a comprehensive range of ocean freight forwarding
              services, without any restrictions on size or weight. Our services
              encompass door-to-door pick-up, advanced tracking technology, and
              efficient management of shipping documents...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/air.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Air">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Air Freight</h5>
                  <p>{companyname} specializes in providing efficient and expedited air
              freight services to destinations worldwide. Our airport-to-airport
              service is designed to cater to customers with high-volume
              business requirements ...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/surface.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Surface">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Surface Transportation</h5>
                  <p>     We offer customized transportation services, with the assurance of
              quality cargo handling. Our goal is to deliver goods in pristine
              condition, thus providing our customers with complete
              satisfaction. We serve our customers with sincerity ...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          ></div>
         
        </div>
      </div>
      {/* Causes End */}

      <Footer />
    </>
  );
}
