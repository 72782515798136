import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Warehousing() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
          Warehousing
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Warehousing
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Warehousing</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/warehousing.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p><br/><br/>
            {companyname} offers comprehensive warehousing services with strategically located facilities throughout the country. Our aim is to help you reduce overhead costs, enhance operational efficiency, and save valuable management time.<br/><br/>

As professional Cargo Warehousing Agents, we prioritize the safe arrival of your products at any destination, anytime. With our integrated inventory management system, we not only provide global control but also offer customers the flexibility to supplement and redistribute cargo, reducing cycle time and ensuring product availability. We have specialized warehousing facilities for containerized, refrigerated, and hazardous materials.<br/><br/>

Our warehousing services include:<br/><br/>

1. Reliable Data Collection and Reporting: We employ state-of-the-art Warehouse Management Systems (WMS) that enable accurate information and visibility on inventories and order processing. These systems can be seamlessly integrated with customer systems for enhanced efficiency.<br/>

2. Comprehensive Services: We offer storage, consolidation, repacking, documentation, labeling, invoicing, finished and spare inventory management, distribution, and service support. Additionally, we handle all logistics activities for spare parts, including repair and reverse logistics, replacement, re-export, and scrapping.<br/>

3. Modern and Well-Equipped Warehouses: Our warehouses are designed with modern infrastructure and equipped with advanced facilities to ensure the safe storage of goods. We prioritize security measures to control the entry and exit of goods, ensuring their protection.<br/>

4. Adequate Insured Private Warehousing Space: We provide adequate private warehousing space that is insured to safeguard your valuable products.<br/>

5. Control Checks: We implement stringent control checks to monitor the movement of goods in and out of our warehouses, ensuring accuracy and minimizing errors.<br/>

6. Warehouse Space at ICDs: We also offer warehouse space at Inland Container Depots (ICDs) for the storage of goods carted directly at these centers, providing convenient access to transport links.<br/><br/>

At {companyname}, we strive to provide efficient and secure warehousing solutions that meet your specific needs. Our commitment to excellence and attention to detail ensure that your goods are stored and managed effectively, allowing you to focus on your core business activities.<br/>
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
