import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Footer = () => {
  return (
    <>
      <div
        className="container-fluid bg-dark text-white-50 footer mt-5 pt-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container py-5">
          <div className="row g-5">
            <div className="col-lg-3 col-md-6">
              <Link to="/Home" className="navbar-brand ms-4 ms-lg-0">
                <img src="img/logo.png" style={{ width: "200px" }} />
              </Link>
              <p>
              With an extensive network of partners worldwide, we take pride
                  in providing our customers with seamless transportation
                  services, including air and sea freight forwarding.
                  Additionally, we offer reliable warehousing and storage
                  solutions, efficient handling of project cargo, break bulk
                  shipments, and chartering services.
              </p>
             
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Address</h5>
              <p>
                <i className="fa fa-map-marker-alt me-3" />
                {companyaddress}
              </p>
              <p>
                <i className="fa fa-phone-alt me-3" />
                {companynumber}
              </p>
              <p>
                <i className="fa fa-envelope me-3" />
                {companyemail}
              </p>
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <Link to="/Home" className="btn btn-link">
                Home 
              </Link>
              <Link to="/About" className="btn btn-link">
                About Us 
              </Link>
              <Link to="/Services" className="btn btn-link">
                Services 
              </Link>
              <Link to="/Careers" className="btn btn-link">
                Careers 
              </Link>
              <Link to="/Contact" className="btn btn-link">
                Contact 
              </Link>
              <Link to="/Getquote" className="btn btn-link">
                Getquote 
              </Link>
             
            </div>
            <div className="col-lg-3 col-md-6">
              <h5 className="text-light mb-4">Quick Links</h5>
              <Link to="/Sea" className="btn btn-link">
                Sea Freight
              </Link>
              <Link to="/Air" className="btn btn-link">
                Air Freight
              </Link>
              <Link to="/Surface" className="btn btn-link">
                Surface Transportation
              </Link>
              <Link to="/Warehousing" className="btn btn-link">
                Warehousing
              </Link>
              <Link to="/Customs" className="btn btn-link">
                Customs Clearance
              </Link>
         
              <Link to="/Multimodal" className="btn btn-link">
                Multimodal Transportation
              </Link>
              <Link to="/Project" className="btn btn-link">
                Project Cargo
              </Link>
            </div>
          </div>
        </div>
        <div className="container-fluid copyright">
          <div className="container">
            <div className="row">
              <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                © <Link to="/Home">{companyname}</Link>, All Right Reserved.
              </div>
              <div className="col-md-6 text-center text-md-end">
                {/*/*** This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. *** /*/}
                <Link className="btn  me-1" to="/Privacy">
                  Privacy Policy
                </Link>
                <Link className="btn  me-1" to="/Terms">
                  Terms & Conditions
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Footer End */}
      {/* Back to Top */}
      <a href="#" className="btn btn-lg btn-primary btn-lg-square back-to-top">
        <i className="bi bi-arrow-up" />
      </a>

      <Outlet />
    </>
  );
};

export default Footer;
