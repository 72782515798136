import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Project() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Project Cargo
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Project Cargo
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Project Cargo</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/project.png"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p>
              <br />
              <br />
              {companyname} are capable enough to handle quick solutions for
              workload management that require experienced / professional,
              dedicated and competent people who are determined to resolve
              issues in a professional manner that arise under special
              circumstances and {companyname} have a
              sense of knowledge to "make things happen".
              <br />
              <br />
              We provide logistics services that include planning, port studies,
              conceptual planning of port operations and other shipping
              services, business and traffic forecasts, financial planning. For
              the implementation of these tasks, "out of the box" thinking is
              necessary to create and implement effective solutions. We use a
              global network of dedicated load handling professionals to handle
              these jobs, from power plants, oil and gas mills to single lift
              trucks. We provide comprehensive cargo handling services for our
              clients. Our services include transporting all machinery,
              equipment and other items that a customer may need. We offer these
              services in packages over a period of time.
              <br />
              <br />
              We can also offer customized services thanks to our extensive
              expertise and industry experience. Managing a project load
              requires experience, resources and the necessary skills.
              <br />
              <br />
              We are well equipped with quality and efficient infrastructure to
              handle all types of projects worldwide. We have experience and
              specialists specialized in cargo ship operations and have access
              to equipment used such as cranes, barges, heavy forklifts for
              marine and land operations.
              <br />
              <br />
              We keep our commitment to daily activities that represent value
              for money, quality, progress, innovation and competence, offering
              services that combine our skills and experience in the fields of
              shipping, logistics and the military water.
              <br />{" "}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
