import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Home() {
  return (
    <>
      <Header />
      {/* Carousel Start */}
      <div className="container-fluid p-0 mb-5">
        <div
          id="header-carousel"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-inner">
            <div className="carousel-item active w3-banner jarallax">
        <video className="img-fluid" autoPlay muted loop style={{ zIndex: -1 }}>
          <source src="img/video.mp4" type="video/mp4" />
        </video>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3 animated slideInDown">
                        Navigating the Global Marketplace, Seamlessly
                      </h1>
                      <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                        As a freight forwarding company, we understand the
                        complexities of the global marketplace. Our expertise
                        lies in navigating through intricate trade regulations,
                        customs procedures, and logistical challenges, ensuring
                        a seamless experience for our clients.{" "}
                      </p>
                      <Link
                        className="btn btn-primary py-2 px-3 animated slideInDown"
                        to="/About"
                      >
                        Learn More
                        <div
                          className="d-inline-flex btn-sm-square  text-primary rounded-circle ms-2"
                          style={{ background: "#2564ce" }}
                        >
                          <i className="fa fa-arrow-right" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item w3-banner jarallax">
        <video className="img-fluid" autoPlay muted loop style={{ zIndex: -1 }}>
          <source src="img/video.mp4" type="video/mp4" />
        </video>
              <div className="carousel-caption">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-lg-7 pt-5">
                      <h1 className="display-4 text-white mb-3 animated slideInDown">
                        Where Trust Meets Timeliness
                      </h1>
                      <p className="fs-5 text-white-50 mb-5 animated slideInDown">
                        Trust is the foundation of our relationship with
                        clients, and we prioritize delivering shipments with
                        unwavering reliability. Our dedicated team works
                        tirelessly to ensure that your cargo reaches its
                        destination on time, every time.{" "}
                      </p>
                      <Link
                        className="btn btn-primary py-2 px-3 animated slideInDown"
                        to="/Contact"
                      >
                        Contact Us
                        <div
                          className="d-inline-flex btn-sm-square  text-primary rounded-circle ms-2"
                          style={{ background: "#2564ce" }}
                        >
                          <i className="fa fa-arrow-right" />
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true" />
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#header-carousel"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true" />
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      {/* Carousel End */}
      {/* About Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100 pt-5 pe-5"
                  src="img/about-1.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <img
                  className="position-absolute top-0 end-0 bg-white ps-2 pb-2"
                  src="img/about-2.jpg"
                  alt=""
                  style={{ width: "200px", height: "200px" }}
                />
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
                  About Us
                </div>
                <h1 className="display-6 mb-5">Welcome to {companyname}</h1>

                <p className="mb-5">
                  With an extensive network of partners worldwide, we take pride
                  in providing our customers with seamless transportation
                  services, including air and sea freight forwarding.
                  Additionally, we offer reliable warehousing and storage
                  solutions, efficient handling of project cargo, break bulk
                  shipments, and chartering services.
                  <br />
                  <br />
                  Our company is dedicated to facilitating international trade
                  and supporting organizations in achieving consistent growth.
                  We handle a wide range of shipping services and engage in
                  custom clearing activities and initiatives to ensure smooth
                  and efficient operations for our clients.
                  <br />
                </p>
                <Link className="btn btn-primary py-2 px-3 me-3" to="/About">
                  Learn More
                  <div
                    className="d-inline-flex btn-sm-square  text-primary rounded-circle ms-2"
                    style={{ background: "#2564ce" }}
                  >
                    <i className="fa fa-arrow-right" />
                  </div>
                </Link>
                <Link
                  className="btn btn-outline-primary py-2 px-3"
                  to="/Contact"
                >
                  Contact Us
                  <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                    <i className="fa fa-arrow-right" />
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About End */}
      {/* Causes Start */}
      <div className="container-xxl bg-light my-5 py-5">
        <div className="container py-5">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Freight Services
            </div>
            <h1 className="display-6 mb-5">
              Shipping Simplified, Success Amplified
            </h1>
          </div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/sea.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Sea">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Sea Freight</h5>
                  <p>We offer a comprehensive range of ocean freight forwarding
              services, without any restrictions on size or weight. Our services
              encompass door-to-door pick-up, advanced tracking technology, and
              efficient management of shipping documents...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/air.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Air">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Air Freight</h5>
                  <p>{companyname} specializes in providing efficient and expedited air
              freight services to destinations worldwide. Our airport-to-airport
              service is designed to cater to customers with high-volume
              business requirements ...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="causes-item d-flex flex-column bg-white border-top border-5 border-primary rounded-top overflow-hidden h-100">
                <div className="position-relative mt-auto">
                  <img
                    className="img-fluid"
                    src="img/surface.jpg"
                    alt=""
                    style={{ height: "250px" }}
                  />
                  <div className="causes-overlay">
                    <Link className="btn btn-outline-primary" to="/Surface">
                      Read More
                      <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                        <i className="fa fa-arrow-right" />
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="text-center p-4 pt-0">
                  <div className="d-inline-block bg-primary text-white rounded-bottom fs-5 pb-1 px-3 mb-4"></div>
                  <h5 className="mb-3">Surface Transportation</h5>
                  <p>     We offer customized transportation services, with the assurance of
              quality cargo handling. Our goal is to deliver goods in pristine
              condition, thus providing our customers with complete
              satisfaction. We serve our customers with sincerity ...</p>
                  <div className="causes-progress bg-light p-3 pt-2"></div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          ></div>
          <div className="row g-4 justify-content-center">
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <i
                  class="fa fa-bullseye fa-6x"
                  style={{ color: "#FF6F0F" }}
                  aria-hidden="true"
                ></i>
                <h4 className="mb-3 mt-3">Our Mission</h4>
                <p className="mb-4">
                  Our mission is to simplify the lives of our customers by
                  offering value-added, innovative, reliable, and cost-effective
                  logistics services that enhance their business efficiency and
                  productivity. We strive to build long-term relationships based
                  on growth, trust, and delivering on our promises. We are
                  dedicated to ensuring the safe and timely delivery of cargo,
                  regardless of its size or location.{" "}
                </p>
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 wow fadeInUp"
              data-wow-delay="0.3s"
            >
              <div className="service-item bg-white text-center h-100 p-4 p-xl-5">
                <i
                  class="fa fa-eye fa-6x"
                  style={{ color: "#FF6F0F" }}
                  aria-hidden="true"
                ></i>
                <h4 className="mb-3 mt-3">Our Vision</h4>
                <p className="mb-4">
                  We aim to establish a prominent global logistics brand that
                  offers fully integrated, cost-effective, and efficient supply
                  chain solutions. Our focus is on delivering exceptional levels
                  of service, reliability, and control by adopting a
                  customer-centric approach. By prioritizing the needs of our
                  clients, we strive to provide comprehensive and seamless
                  logistics services that optimize their operations and drive
                  their success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Causes End */}

      {/* Team Start */}
      <div className="container-xxl py-5">
        <div className="container">
          <div
            className="text-center mx-auto mb-5 wow fadeInUp"
            data-wow-delay="0.1s"
            style={{ maxWidth: "500px" }}
          >
            <div className="d-inline-block rounded-pill bg-secondary text-primary py-1 px-3 mb-3">
              Specialized Services
            </div>
          </div>
          <div className="row g-4">
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/warehousing.jpg"
                    style={{ height: "200px" }}
                    alt=""
                  />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>Warehousing</h5>
                  <Link
                    className="btn btn-outline-primary px-3"
                    to="/Warehousing"
                  >
                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                      <i className="fa fa-arrow-right" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/customs.webp"
                    style={{ height: "200px" }}
                    alt=""
                  />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>Customs Clearance</h5>
                  <Link className="btn btn-outline-primary px-3" to="/Customs">
                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                      <i className="fa fa-arrow-right" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/multimodal.jpg"
                    style={{ height: "200px" }}
                    alt=""
                  />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>Multimodal Transport</h5>
                  <Link
                    className="btn btn-outline-primary px-3"
                    to="/Multimodal"
                  >
                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                      <i className="fa fa-arrow-right" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <div className="team-item position-relative rounded overflow-hidden">
                <div className="overflow-hidden">
                  <img
                    className="img-fluid"
                    src="img/project.png"
                    style={{ height: "200px" }}
                    alt=""
                  />
                </div>
                <div className="team-text bg-light text-center p-4">
                  <h5>Project Cargo</h5>
                  <Link className="btn btn-outline-primary px-3" to="/Project">
                    Learn More
                    <div className="d-inline-flex btn-sm-square bg-primary text-white rounded-circle ms-2">
                      <i className="fa fa-arrow-right" />
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Team End */}

      <Footer />
    </>
  );
}
