import { Outlet, Link } from "react-router-dom";
import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "./Companyinfo";

const Header = () => {
  return (
    <>
     
      
        {/* Navbar Start */}
        <div className="container-fluid fixed-top px-0  fadeIn">
          <div className="top-bar text-white-50 row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
              <small><i className="fa fa-phone me-2" />{companynumber}</small>
              <small className="ms-4"><i className="fa fa-envelope me-2" />{companyemail}</small>
            </div>
            <div className="col-lg-2 px-5 text-end">
              

              
            </div>
            <div className="col-lg-2 px-5 text-end">
              <small><Link to="/Privacy">Privacy Policy</Link></small>
              

              
            </div>
            <div className="col-lg-2 px-5 text-end">
              
              <small><Link to="/Terms">Terms & Conditions</Link></small>

              
            </div>
          </div>
          <nav className="navbar navbar-expand-lg  py-lg-0 px-lg-5 wow fadeIn" data-wow-delay="0.1s">
            <Link to="/Home" className="navbar-brand ms-4 ms-lg-0">
              <img src="img/logo.png" style={{width:'200px'}} />
            </Link>
            <button type="button" className="navbar-toggler me-4" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-4 p-lg-0">
                <Link to="/Home" className="nav-item nav-link active">Home</Link>
                <Link to="/About" className="nav-item nav-link">About Company</Link>
                <div className="nav-item dropdown">
                  <Link to="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Our Services</Link>
                  <div className="dropdown-menu m-0">
                    <Link to="/Sea" className="dropdown-item">Sea Freight</Link>
                    <Link to="/Air" className="dropdown-item">Air Freight</Link>
                    <Link to="/Surface" className="dropdown-item">Surface Transportation</Link>
                    <Link to="/Warehousing" className="dropdown-item">Warehousing</Link>
                    <Link to="/Customs" className="dropdown-item">Customs Clearance</Link>
                    <Link to="/Multimodal" className="dropdown-item">Multimodal Transportation</Link>
                    <Link to="/Project" className="dropdown-item">Project Cargo</Link>

                   
                  </div>
                </div>
                <Link to="/Careers" className="nav-item nav-link">Careers</Link>
                <Link to="/Contact" className="nav-item nav-link">Contact</Link>
              </div>
              <div className="d-none d-lg-flex ms-2">
                <Link className="btn btn-outline-primary py-2 px-3" to="/Getquote">
                 Request a Quote
                  <div className="d-inline-flex btn-sm-square  text-primary rounded-circle ms-2" style={{background:'#2564ce'}}>
                    <i className="fa fa-arrow-right" />
                  </div>
                </Link>
              </div>
            </div>
          </nav>
        </div>
        {/* Navbar End */}
      

      <Outlet />
    </>
  );
};

export default Header;
