import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Surface() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Surface Transportation
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Surface Transportation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Surface Transportation</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/surface.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p>
              <br />
              <br />
              We offer customized transportation services, with the assurance of
              quality cargo handling. Our goal is to deliver goods in pristine
              condition, thus providing our customers with complete
              satisfaction. We serve our customers with sincerity and
              dedication, thus providing quality and reliable service.
              <br />
              <br />
              Our customers appreciate our travel services a lot. We use the
              best vehicles to transport products from one place to another. Our
              service standards for car transportation are also tailored to the
              needs of growing companies and businesses. Services with
              well-defined delivery times give our customers the security they
              need to establish reliable budgets and manage inventory
              effectively. We specialize in transporting our clients' goods from
              one place to another with full security guarantees. Working with
              the principles of care and perfection, we provide fast and
              reliable service. The goal of our company is to deliver products
              in flawless condition.
              <br />
              <br />
              We stand out and pride ourselves on being one of the most customer
              focused companies around. Safety and timely delivery are the
              highest goals of the company. We have served customers with
              reliable, efficient and professional services. Our organization
              strives to deliver excellence in everything we do. Our quality
              service meets international standards, as we believe in long-term
              business relationships with our customers. <br />
              <br />
              We offer:
              <br />
              <br />
              General cargo transportation.
              <br />
              Transportation of heavy and bulky packages. <br />
              Coordinate all clearances from PWD/RTO/Police/Forest Department.
              etc
              <br />
              A study of ports and routes for transporting heavy and bulky
              goods.
              <br />
              Multimodal transport by road / rail / boat etc.
              <br />
              Special transport of very heavy packages by trailer with hydraulic
              axles.
              <br />
              Delivery of goods on site.
              <br />{" "}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
