import Header from "../components/Header";
import Footer from "../components/Footer";
import { Outlet, Link } from "react-router-dom";

import {
  companyname,
  companyurl,
  companyemail,
  companyaddress,
  companynumber,
} from "../components/Companyinfo";

export default function Multimodal() {
  return (
    <>
      <Header />

      {/* Page Header Start */}
      <div
        className="container-fluid page-header mb-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <div className="container text-center">
          <h1 className="display-4 text-white animated slideInDown mb-4">
            Multimodal Transportation
          </h1>
          <nav aria-label="breadcrumb animated slideInDown">
            <ol className="breadcrumb justify-content-center mb-0">
              <li className="breadcrumb-item">
                <Link className="text-white" to="/Home">
                  Home
                </Link>
              </li>
              <li
                className="breadcrumb-item text-primary active"
                aria-current="page"
              >
                Multimodal Transportation
              </li>
            </ol>
          </nav>
        </div>
      </div>
      {/* Page Header End */}
      <section class="why_section layout_padding">
        <div class="container">
          <div class="heading_container text-center">
            <h2>Multimodal Transportation</h2>
          </div>
          <div className="wow fadeInUp" data-wow-delay="0.1s">
            <div
              className="position-relative overflow-hidden h-100"
              style={{ minHeight: "600px" }}
            >
              <img
                className="position-absolute w-100 h-100 pt-5 pe-5"
                src="img/multimodal.jpg"
                alt=""
                style={{ objectFit: "cover" }}
              />
            </div>
          </div>
          <div
            className="col-lg-12 col-md-6 col-sm-12 wow fadeIn"
            data-wow-delay=".5s"
          >
            <p>
              <br />
              <br />
              Multimodal transport services chez {companyname} is an efficient
              chain that combines different links or modes of transport - air,
              sea and land into one complete system that provides door-to-door
              transport efficiently and effectively cost under one house. Our
              extensive network and high capacity allow us to provide you with
              fast and flexible service. Our rail, road and sea connections
              allow us to serve our customers with a balanced approach to meet
              the needs of door-to-door transport, or small shipments. , LTL or
              full load. <br />
              <br /> By covering long distances by train, we can provide a
              travel solution. Our multimodal transportation system serves as a
              key link in our larger supply chain management system. We
              transport your cargo from the point of origin to the port and from
              the port of delivery to the destination in an economical and
              timely manner. We work hard to ensure that any issues that may
              arise are resolved in a reasonable manner and based on your needs.
              We are often thought of as a shipping company, but that is only
              part of the picture.
              <br />
              <br /> As a multimodal transport operator, we take responsibility
              for the execution of multimodal transport contracts with carriers
              participating in multimodal transport operations. We have good
              business relations with individual transport operators and provide
              the best service. Our various transports, organized and
              coordinated in one service, reduce the loss of time and the risk
              of loss, theft and damage to the product at the point of delivery.
              We maintain excellent communication links and organize transfers
              and transportation efficiently at transfer points.{" "}
            </p>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}
